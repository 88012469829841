import {Box, Typography} from "@mui/material";
import React from "react";
import {Appointment} from "../../../api/appointment/appointment.interface";
import theme from "../../../assets/theme";
import ScrollableBox from "../../../components/common/ScrollableBox";
import AppointmentListItem from "./AppointmentListItem";
import NoAppointmentsSidebarSvg from "./../../../assets/images/no-appointments-sidebar.svg";
import {noAppointmentsContainer} from "./styles";
interface Props {
  appointments: Appointment[];
}

const AppointmentsInProgress = ({appointments}: Props) => {
  return (
    <Box sx={{mt: 5, mx: 2}}>
      <Typography fontSize={"medium"} color={theme.colors.text.secondary} fontWeight="550">
        IN PROGRESS
      </Typography>
      {appointments.length !== 0 && (
        <ScrollableBox
          sx={{
            maxHeight: "300px",
          }}>
          {appointments.map(appointment => {
            return <AppointmentListItem key={appointment.id} appointment={appointment} />;
          })}
        </ScrollableBox>
      )}
      {appointments.length === 0 && (
        <Box sx={theme => noAppointmentsContainer(theme)}>
          <Box component="img" src={NoAppointmentsSidebarSvg} sx={{width: "12rem"}} />
          <Typography variant="body2" mt={3} fontSize={"14px"} textAlign={"center"}>
            Your appointments haven’t started for today
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default AppointmentsInProgress;
