export const LOGIN_API_URL = "account/auth/login/";
export const LOGOUT_API_URL = "account/auth/logout/";
export const SEND_EMAIL_OTP_API_URL = "account/auth/send_email_otp/";
export const VERIFY_EMAIL_OTP_API_URL = "account/auth/verify_email_otp/";
export const SIGNUP_USER_API_URL = "account/auth/create_user/";
export const FORGOT_PASSWORD_API_URL = "account/auth/forgot_password/";
export const CONFIRM_FORGOT_PASSWORD_API_URL = "account/auth/forgot_password/confirm/";
export const GET_USER_METADATA_API_URL = "account/auth/get_user_metadata/";
export const LIST_EMR_SYSTEMS_API_URL = "account/list_emr_systems/";
export const APPOINTMENT_LIST_API_URL = "account/appointments/";
export const PRACTITIONER_API_URL = "account/practitioner/";
export const FETCH_PATIENTS_API_URL = "account/patients/";
export const CREATE_APPOINTMENT_API_URL = "account/appointments/";
export const CREATE_RECORD_API_URL = "account/records/create/";
export const CREATE_SIGNED_API_URL = "account/records/signed_url/";
export const COMPLETE_RECORD_API_URL = "account/records/complete/";
export const GET_CUSTOM_USER_TEMPLATES_API_URL = "account/custom_user_templates/";
export const CHANGE_PASSWORD_API_URL = "account/auth/change_password/";

// For API URLs with params
export const getAppointmentDetailsApiUrl = (appointment_id: number) =>
  `account/appointment/${appointment_id}/detail/`;
export const updateAppointmentApiUrl = (appointment_id: number) =>
  `account/appointment/${appointment_id}/update/`;
export const getRecordsUrl = (appointment_id: number) =>
  `account/appointment/${appointment_id}/record_transcript/`;
export const getTranscriptionApiUrl = (record_id: number) =>
  `account/record/${record_id}/transcription/`;
export const getTranscriptionStatusApiUrl = (record_id: number) =>
  `account/record/${record_id}/transcription/status/`;
export const getSoapNotesApiUrl = (appointment_id: number) =>
  `account/appointment/${appointment_id}/soap_note_data/`;
export const getGenerateSoapNotesApiUrl = (appointment_id: number) =>
  `account/appointment/${appointment_id}/soap_note_data/generate/`;
export const getSoapNotesGenerationStatusApiUrl = (appointment_id: number) =>
  `account/appointment/${appointment_id}/soap_note_data/status/`;
export const getPushSoapNotesApiUrl = (appointment_id: number) =>
  `account/appointment/${appointment_id}/soap_note_data/push/`;
export const getPatientDetailsApiUrl = (patient_id: number) => `account/patient/${patient_id}/`;
